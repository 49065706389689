import React, { useCallback } from "react";
import { navigate } from "@reach/router";
import { Context as NavigationContext } from "../../provider/navigation";
import { Context as ContentContext } from "../../provider/content";
import { Button } from "../../components/button";
import ContactsPhoto from "../../assets/backgrounds/contacts.jpg";

import "./registration.scss";

const Registration = () => {
  const { setBackground, setTheme } = React.useContext(NavigationContext);
  const { settings: { token, school } = {} } = React.useContext(ContentContext);

  setTheme("transparent");
  setBackground(ContactsPhoto);

  const requiredFields = [
    "name",
    "age",
    "gender",
    "university",
    "faculty",
    "course",
    "phone",
    "email",
    "social",
    "speciality",
    "cv",
    "letter",
    "hotel",
    "tshirt",
    "about",
  ];

  const [state, setState] = React.useState({
    name: "",
    age: "",
    gender: "",
    university: "",
    faculty: "",
    course: "",
    phone: "",
    email: "",
    social: "",
    speciality: "",
    cv: "",
    letter: "",
    help: "",
    hotel: "",
    tshirt: "",
    about: "",
    suggestions: "",
  });

  const checkIsFilled = useCallback(() => {
    const requiredFieldsFilled = requiredFields.every(
      (fieldKey) => state[fieldKey]
    );
    const minTextSizeReached =
      state.letter.split(" ").length > 50 && state.cv.split(" ").length > 50;
    return requiredFieldsFilled && minTextSizeReached;
  });

  const [filled, setFilled] = React.useState(checkIsFilled);
  React.useEffect(() => {
    setFilled(checkIsFilled);
    console.log(checkIsFilled());
  }, [checkIsFilled, state]);

  const [textSizes, setTextSizes] = React.useState({
    cv: 0,
    letter: 0,
  });

  const [otherSexInputActive, setActiveSexInput] = React.useState(false);
  const [otherFacultyInputActive, setActiveFacultyInput] =
    React.useState(false);

  const onSexValueChange = (e) => {
    const value = e.target.value;
    setActiveSexInput(value === "other");
    setState((state) => ({
      ...state,
      gender: value === "other" ? state.otherGender : value,
    }));
  };

  const onFacultyValueChange = (e) => {
    const value = e.target.value;
    setActiveFacultyInput(value === "other");
    setState((state) => ({
      ...state,
      faculty: value === "other" ? state.otherFaculty : value,
    }));
  };

  const onInputChange = (key) => (e) => {
    const value = e.target.value;
    setState((state) => ({ ...state, [key]: value }));
  };

  const handleSumbit = (e) => {
    e.preventDefault();

    const bodyData = {
      name: state.name,
      age: state.age,
      gender: state.gender || state.otherGender,
      university: state.university,
      faculty: state.faculty || state.otherFaculty,
      course: state.course,
      phone: state.phone,
      email: state.email,
      social: state.social,
      specialty: state.speciality,
      future_specialty: state.speciality,
      cv_text: state.cv,
      letter_text: state.letter,
      desire_to_help: state.help,
      hotel: state.hotel,
      tshirt_size: state.tshirt,
      hear_about_school: state.about,
      suggestions: state.suggestions,
    };

    console.log(bodyData);

    if (!filled) return;

    fetch("https://api.vera.school/requests", {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        request: bodyData,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        navigate("/form-filled");
      })
      .catch((err) => console.error(err));
  };

  return (
    <form className="registration">
      <div className="row">
        <h1>Подача заявки</h1>
      </div>

      <div className="row background title">
        <div className="content">
          <h4>
            Школа по ВИЧ-инфекции для будущих врачей «VERA HIV Med School»
          </h4>
          <p>
            Следующая школа: {school && school.date} <br />{" "}
            {school && school.requests_date}{" "}
          </p>
        </div>
      </div>

      <div className="row">
        <p className="form-subtitle">Информация о Вас</p>
        <div className="form-row">
          <div className="form-field width-60">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Фамилия Имя Отчество *"
              required
              onChange={onInputChange("name")}
              value={state.name}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-field width-20">
            <input
              type="number"
              name="age"
              id="age"
              placeholder="Возраст *"
              required
              onChange={onInputChange("age")}
              value={state.age}
            />
          </div>

          <div className="form-field width-40">
            <select
              id="sex"
              name="sex"
              onChange={onSexValueChange}
              defaultValue={state.gender}
              className={
                state.gender === "" && !otherSexInputActive ? "empty" : ""
              }
              required
            >
              <option value="Мужской">Мужской</option>
              <option value="Женский">Женский</option>
              <option value="other">Другой</option>
              <option value="" hidden disabled>
                Пол *
              </option>
            </select>
          </div>

          {otherSexInputActive && (
            <div className="form-field width-40 other-input-field">
              <input
                type="text"
                name="other-sex"
                id="other-sex"
                placeholder="Пол *"
                required
                onChange={onInputChange("otherGender")}
              />
            </div>
          )}
        </div>

        <p className="form-subtitle">Информация об учебном заведении</p>

        <div className="form-row">
          <div className="form-field width-40">
            <input
              type="text"
              name="university"
              id="university"
              placeholder="ВУЗ *"
              required
              onChange={onInputChange("university")}
              value={state.university}
            />
          </div>

          <div className="form-field width-20">
            <input
              type="number"
              name="course"
              id="course"
              placeholder="Курс *"
              required
              onChange={onInputChange("course")}
              value={state.course}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-field width-60">
            <select
              id="faculty"
              name="faculty"
              defaultValue={state.faculty}
              onChange={onFacultyValueChange}
              className={
                state.faculty === "" && !otherFacultyInputActive ? "empty" : ""
              }
              required
            >
              {/* <option value="" disabled selected={true}>Факультет *</option> */}
              <option value="Ординатура">Ординатура</option>
              <option value="Лечебный">Лечебный</option>
              <option value="Педиатрический">Педиатрический</option>
              <option value="Медико-профилактический">
                Медико-профилактический
              </option>
              <option value="Стоматологический">Стоматологический</option>
              <option value="other">Другой</option>
              <option value="" hidden disabled>
                Факультет *
              </option>
            </select>
          </div>

          {otherFacultyInputActive && (
            <div className="form-field width-40 other-input-field">
              <input
                type="text"
                name="other-faculty"
                id="other-faculty"
                placeholder="Факультет *"
                required
                onChange={onInputChange("otherFaculty")}
              />
            </div>
          )}
        </div>

        <div className="form-row with-text">
          <div className="form-field width-60">
            <input
              type="text"
              name="speciality"
              id="speciality"
              placeholder="Специальность *"
              onChange={onInputChange("speciality")}
              required
            />
          </div>

          <div className="form-field width-40 text">
            Если еще не определились — оставьте поле "Специальность" пустым.
          </div>
        </div>

        <p className="form-subtitle">Контактные данные и соц. сети</p>

        <div className="form-row with-text">
          <div className="form-field width-60">
            <input
              type="tel"
              name="phone"
              id="phone"
              placeholder="Ваш номер телефона *"
              required
              onChange={onInputChange("phone")}
              value={state.phone}
            />
          </div>

          <div className="form-field width-40 text">
            Для участников школы будет создана группа в Telegram.
          </div>
        </div>

        <div className="form-row">
          <div className="form-field width-60">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="E-mail *"
              required
              onChange={onInputChange("email")}
              value={state.email}
            />
          </div>
        </div>

        <div className="form-row with-text">
          <div className="form-field width-60">
            <input
              type="text"
              name="social"
              id="social"
              placeholder="VK, Facebook, Instagram *"
              required
              onChange={onInputChange("social")}
              value={state.social}
            />
          </div>

          <div className="form-field width-40 text">
            Укажите ссылки на профили в социальных сетях
          </div>
        </div>
      </div>

      <div className="row background attachments">
        <div className="content">
          <h4>
            Уделите особое внимание следующим пунктам. Резюме и мотивационные
            письма короче 50 слов к рассмотрению не принимаются.
          </h4>

          <div className="form-row with-text">
            <div className="form-field width-60 textarea-wrapper">
              <textarea
                id="cv"
                name="cv"
                placeholder="Ваше резюме (CV) *"
                required
                onChange={(e) => {
                  const cv = e.target.value;
                  const wordsCount =
                    cv.replace(/\s+/g, " ").split(" ").length - 1;
                  if (wordsCount > 500) return;
                  setTextSizes((prevState) => ({
                    ...prevState,
                    cv: wordsCount,
                  }));
                  onInputChange("cv")(e);
                }}
                value={state.cv}
              />
              <span className="count">{textSizes.cv}/500</span>
            </div>

            <div className="form-field width-40 text">
              Внесите подробное описание (по пунктам) вашего опыта учебной,
              научной и волонтерской деятельности, как по теме ВИЧ-инфекции, так
              и в других сферах. Объем 50–500 слов. Если вы не знаете, как
              писать резюме, пожалуйста, ознакомьтесь с примерами резюме из
              интернета.
            </div>
          </div>

          <div className="form-row with-text">
            <div className="form-field width-60 textarea-wrapper">
              <textarea
                id="letter"
                name="letter"
                placeholder="Мотивационное письмо *"
                required
                onChange={(e) => {
                  const letter = e.target.value;
                  const wordsCount =
                    letter.replace(/\s+/g, " ").split(" ").length - 1;
                  if (wordsCount > 500) return;
                  setTextSizes((prevState) => ({
                    ...prevState,
                    letter: wordsCount,
                  }));
                  onInputChange("letter")(e);
                }}
                value={state.letter}
              />
              <span className="count">{textSizes.letter}/500</span>
            </div>

            <div className="form-field width-40 text">
              Расскажите о себе, опишите ваш личный опыт, связанный с темой
              ВИЧ-инфекции, и подробно ответьте на вопросы: «Почему организаторы
              должны выбрать именно вас? Что вам позволит участие в данной
              школе?». Объем 50–500 слов. Если вы не знаете, как писать
              мотивационное письмо, пожалуйста, ознакомьтесь с примерами из
              интернета.
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-row with-text">
          <div className="form-field width-60">
            <textarea
              name="help"
              id="help"
              placeholder="Могу помочь"
              onChange={onInputChange("help")}
            />
          </div>

          <div className="form-field width-40 text">
            Есть ли что-то, с чем вы готовы помочь организаторам школы?
            Например, вы фотографируете и готовы сделать фотографии, снять
            видеоролик о школе, распространить информацию о школе среди коллег.
          </div>
        </div>

        <div className="form-row with-text">
          <div className="form-field width-60 checkbox">
            <h5>Вам нужен отель? * </h5>
            <label className="container">
              Да, нужен
              <input
                name="hotel"
                value="Да, нужен"
                type="radio"
                required
                onChange={onInputChange("hotel")}
              />
              <span className="checkmark"></span>
            </label>
            <label className="container">
              Нет, не нужен
              <input
                name="hotel"
                value="Нет, не нужен"
                type="radio"
                required
                onChange={onInputChange("hotel")}
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div className="form-field width-40 text">
            Если вы живете в Москве или собираетесь остановиться у знакомых —
            выбирайте "нет".
          </div>
        </div>

        <div className="form-row with-text">
          <div className="form-field width-60">
            <select
              id="tshirt"
              name="tshirt"
              onChange={onInputChange("tshirt")}
              defaultValue={state.tshirt}
              className={state.tshirt === "" ? "empty" : ""}
              required
            >
              <option value="Женская XS">Женская XS</option>
              <option value="Женская S">Женская S</option>
              <option value="Женская M">Женская M</option>
              <option value="Женская XL">Женская XL</option>
              <option value="Мужская XS">Мужская XS</option>
              <option value="Мужская S">Мужская S</option>
              <option value="Мужская M">Мужская M</option>
              <option value="Мужская XL">Мужская XL</option>
              <option disabled hidden value="">
                Размер футболки *
              </option>
            </select>
          </div>
        </div>

        <div className="form-row with-text">
          <div className="form-field width-60">
            <input
              type="text"
              name="about"
              id="about"
              placeholder="Откуда вы узнали о школе? *"
              onChange={onInputChange("about")}
              value={state.about}
              required
            />
          </div>

          <div className="form-field width-40 text">
            Укажите ссылку на конкретный источник или назовите человека, от
            которого вы узнали о школе.
          </div>
        </div>

        <div className="form-row with-text">
          <div className="form-field width-60">
            <textarea
              type="text"
              name="suggestions"
              id="suggestions"
              placeholder="Предложения / пожелания"
              onChange={onInputChange("suggestions")}
              value={state.suggestions}
            />
          </div>

          <div className="form-field width-40 text">
            Предложения / пожелания по программе школы.
          </div>
        </div>

        <div className="form-row reminder-row">
          <div className="form-field width-60 reminder">
            <span>
              Напоминаем, что дорога до Москвы и обратно оплачивается
              самостоятельно
            </span>
          </div>
        </div>

        <div className="form-row">
          <div className="form-field width-60">
            <Button disabled={!filled} type="submit" onClick={handleSumbit}>
              Отправить заявку
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Registration;
