import React from "react";
import { Context as ContentContext } from "../../provider/content";
import { Context as NavigationContext } from "../../provider/navigation";
import AboutPhoto from "../../assets/backgrounds/about.jpg";
import AuthorBrezhneva from "../../assets/author-brezhneva.png";
import AuthorEremin from "../../assets/author-eremin.png";
import Gallery from "../../components/gallery";
import Blockquote from "../../components/blockquote";
import { LinkButton } from "../../components/button";
import Photos from "../../components/photos";

import "./about.scss";

const AboutPage = () => {
  const { setBackground, setTheme } = React.useContext(NavigationContext);
  const { articles, settings: { school: { date } = {} } = {} } =
    React.useContext(ContentContext);

  setTheme("transparent");
  setBackground(AboutPhoto);

  const [pages, setPages] = React.useState();
  React.useLayoutEffect(() => {
    function updatePages() {
      setPages(window.innerWidth >= 1024 ? 2 : 1);
    }

    window.addEventListener("resize", updatePages);
    updatePages();
    return () => window.removeEventListener("resize", updatePages);
  }, []);

  return (
    <div className="about">
      {/* <img src={SharedImage} alt=""/> */}
      <div className="row lead">
        <h1>Vera HIV Med School</h1>

        <a href="/registration">
          <div className="next-school">
            <span class="text">Следующая школа</span>
            <br />
            <span class="date">{date}</span>
          </div>
        </a>

        <p>
          Школа «ВИЧ-инфекция для будущих врачей различных специальностей» Vera
          HIV med school — просветительский проект фонда СПИД.ЦЕНТР.
          <br />
          На каждую школу производится отбор 25 медицинских студентов,
          ординаторов и молодых врачей из разных регионов России и стран региона
          Восточной Европы и Центральной Азии. Конечной целью этого проекта
          является повышение уровня осведомленности будущих врачей по теме
          ВИЧ-инфекции и снижение уровня дискриминации в сфере здравоохранения.
        </p>
      </div>

      <Gallery
        items={Object.values(Photos).map((photo) => ({ photo }))}
        itemsPerPage={1}
      />

      <Blockquote
        text={`Основная задача организаторов — сделать так, чтобы современные врачи забыли про «чуму XX века»
          и обратились к актуальным знаниям и научным достижениям.`}
        authorName="Антон Еремин"
        authorDesc="Фонд «СПИД.ЦЕНТР»"
        authorPhoto={AuthorEremin}
      />

      <Gallery
        items={
          articles
            ? articles.map((article) => ({
                photo: `https://api.vera.school/${article.photo_url}`,
                content: {
                  date: article.dates,
                  title: article.title,
                  desc: article.lead,
                  link: article.url_sc,
                  unaidsLink: article.url_unaids,
                },
              }))
            : []
        }
        itemsPerPage={pages}
      />

      <div className="row">
        Первая школа для молодых врачей состоялась при финансовой поддержке
        певицы и посла доброй воли объединенной программы ООН по ВИЧ/СПИДу
        (ЮНЭЙДС) Веры Брежневой. Именно поэтому школа носит ее имя. С осени 2018
        года школы молодых врачей, проводимые фондом СПИД.ЦЕНТР, проходят четыре
        раза в год.
      </div>

      <Blockquote
        text={`Одной из самых страшных проблем является дискриминация, особенно врачебная, в медицинских учреждениях.
          Отсутствие человеческого отношения там, куда люди приходят за помощью.
          Вы, как будущие врачи, сможете помочь этим людям и помочь своим коллегам,
          объяснив, рассказав им то, что узнаете здесь, на школе.`}
        authorName="Вера Брежнева"
        authorDesc="Посол UNAIDS, певица, актриса и телеведущая"
        authorPhoto={AuthorBrezhneva}
      />

      <div className="row about-video">
        <div className="about-video-wrapper">
          <iframe
            width="980"
            height="500"
            src="https://www.youtube.com/embed/yko0Fsu2BmI"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <div className="about-video-desc">
          Видео Ольги Пендриковой (Гомель), участвовавшей в 4 сезоне школы
          молодых врачей.
        </div>
      </div>

      {/* enable when open
                <div className="row participants-registration">
        <LinkButton to="/registration">Подать заявку на участие</LinkButton>
      </div>
          */}
    </div>
  );
};

export default AboutPage;
