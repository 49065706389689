import React from "react";
import Navigation, { MobileNavigation } from "../navigation";
import VeraLogo from "../../assets/vera-logo.png";
import { Link } from "@reach/router";
import { Context as ContentContext } from "../../provider/content";
import AidsCenterLogo from "../../assets/aids-center-logo.png";

import "./header.scss";

const Header = () => {
  const { settings: { school: { date } = {} } = {} } =
    React.useContext(ContentContext);

  const Logo = (
    <Link to="/">
      <img src={VeraLogo} alt="Vera HIV Med School" />
    </Link>
  );

  return (
    <div className="header">
      <div className="header-title bold">
        <div className="header-link">
          <Link to="/">Vera HIV med school</Link>
          <a className="header-img-link" href="https://spid.center">
            <img
              className="header-other-logo"
              src={AidsCenterLogo}
              alt="AidsCenter Logo"
            />
          </a>
        </div>
        {Logo}
        <div className="header-link registration">
          <Link aria-disabled="true" to="/registration">
            Подать заявку
          </Link>
        </div>
      </div>
      <Navigation />
      <MobileNavigation logo={Logo} />
    </div>
  );
};

export default Header;
